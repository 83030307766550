<template>
  <v-dialog
    v-model="dialog"
    width="550px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="elevation-0 rounded-xl">
      <v-card-title>
        <v-row>
          <v-col class="d-flex justify-center mb-4 mb-md-8 mt-3">
            <v-avatar size="110" :color="`${theme} lighten-4`">
              <v-icon :color="`${theme}`" x-large>mdi-{{ icon }}</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-card
          class="grey lighten-4 elevation-0 pa-5 mb-2 mb-md-6 mx-md-5 rounded-lg"
        >
          <p class="text-h5 font-weight-bold text-center">
            {{ title }}
          </p>
          <v-row>
            <v-col cols="12">
              <slot name="body"></slot>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-card-actions>
        <v-row class="mb-3 mb-md-5 px-2">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              @click="toggleModal()"
              depressed
              large
              color="primary rounded-md"
              :disabled="savingEconomicManagerState"
              :loading="savingEconomicManagerState"
            >
              Aceptar
              <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GeneralInformativeModal",

  data() {
    return {
      dialog: false,
    };
  },

  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    theme: {
      type: String,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
