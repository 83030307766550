<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark class="elevation-0" color="indigo lighten-4">
        <v-btn
            v-if="persistent"
            class="red lighten-4"
            @click="closedFromToolbar()"
        >
          <v-icon color="red" left>mdi-close</v-icon>
          <span class="red--text font-weight-bold">Cerrar</span>
        </v-btn>
        <v-row>
          <v-col :cols="persistent ? '11' : '12'" class="d-flex justify-center">
            <p
                class="font-weight-bold text-center text-h5 mb-0 d-none d-sm-inline  indigo--text"
            >
              Formulario para confirmación de sostenedor económico, facturación
              electrónica, firma de contrato y pagaré
            </p>
            <p
                class="font-weight-bold text-center text-h5 mb-0 d-sm-none indigo--text"
            >
              Formulario de facturación y representante
            </p>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container>
        <v-row class="mt-2 d-flex justify-center">
          <p class="text-h5 text-center">
            Registro de sostenedor económico del estudiante:
            <br/>
            <span class="font-weight-bold">
              {{ this.currentUserPersonalInfo.first_name }}
              {{ this.currentUserPersonalInfo.last_name }}
            </span>
          </p>
          <v-col cols="12" sm="8" md="12">
            <v-alert prominent dense text type="warning" class="rounded-lg">
              Los datos solicitados en este formulario serán usados para
              confirmación de sostenedor económico, facturación electrónica,
              firma de contrato y pagaré.
              <span class="font-weight-bold"
              >Todos los datos solicitados son obligatorios.</span
              >
              <!-- <p
                class="mb-0 text-muted text-h6 font-weight-normal text-center"
              >
                Todos los campos son obligatorios *
              </p> -->
            </v-alert>
          </v-col>
        </v-row>

        <!-- Here's where the form starts -->
        <v-row class="d-flex justify-center justify-md-start">
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
                name="Nombre"
                :validator="$v.studentEconomicManager.first_name"
            >
              <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  label="Nombre"
                  v-model.trim="studentEconomicManager.first_name"
                  @input="$v.studentEconomicManager.first_name.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
                name="Apellido"
                :validator="$v.studentEconomicManager.last_name"
            >
              <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  label="Apellido"
                  v-model.trim="studentEconomicManager.last_name"
                  @input="$v.studentEconomicManager.last_name.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>

          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
                name="de correo electrónico"
                :validator="$v.studentEconomicManager.email"
            >
              <v-text-field
                  @input="$v.studentEconomicManager.email.$touch()"
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  label="Correo electrónico"
                  v-model.trim="studentEconomicManager.email"
              ></v-text-field>
            </form-group>
          </v-col>

          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group
                name="Número de teléfono"
                :validator="$v.studentEconomicManager.cellphone"
            >
              <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  v-mask="'########'"
                  label="Número de teléfono"
                  :error-messages="phoneErrors"
                  v-model.trim="studentEconomicManager.cellphone"
                  @input="$v.studentEconomicManager.cellphone.$touch()"
              ></v-text-field>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="Departamento" :validator="$v.selectedDepartment">
              <v-select
                  :loading="loadingDepartments"
                  :disabled="loadingDepartments"
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  :items="departmentsList"
                  item-value="id"
                  item-text="name"
                  return-object
                  label="Departamento"
                  v-model="selectedDepartment"
                  @change="getMunicipalities(selectedDepartment.id)"
                  @input="$v.selectedDepartment.$touch()"
              ></v-select>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="Municipio" :validator="$v.selectedMunicipality">
              <v-select
                  :loading="loadingMunicipalities"
                  :disabled="
                  loadingMunicipalities ||
                    Object.keys(selectedDepartment).length === 0
                "
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  :items="municipalitiesList"
                  item-value="id"
                  item-text="name"
                  return-object
                  rounded
                  label="Municipio"
                  v-model="selectedMunicipality"
                  @input="$v.selectedMunicipality.$touch()"
              ></v-select>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="12">
            <form-group
                name="dirección"
                :validator="$v.studentEconomicManager.address"
            >
              <v-textarea
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  counter
                  filled
                  clearable
                  rounded
                  auto-grow
                  rows="2"
                  label="Dirección"
                  v-model.trim="studentEconomicManager.address"
                  @input="$v.studentEconomicManager.address.$touch()"
                  @blur="
                  () =>
                    $v.studentEconomicManager.address &&
                    $v.studentEconomicManager.address.$touch()
                "
                  @paste="handleEmptySpaces($event, 'address')"
                  @keyup.enter="handleKeyUpAddress()"
              ></v-textarea>
            </form-group>
          </v-col>
          <v-col cols="12" sm="8" md="6" lg="4" xl="3">
            <form-group name="DUI" :validator="$v.studentEconomicManager.dui">
              <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  filled
                  rounded
                  v-mask="'########-#'"
                  label="DUI"
                  v-model.trim="studentEconomicManager.dui"
                  @input="$v.studentEconomicManager.dui.$touch()"
                  :error-messages="duiErrors"
              ></v-text-field>
            </form-group>
          </v-col>

        </v-row>

        <v-row class="mb-5">
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
                @click="validateStudentEconomicManager()"
                :loading="isSavingEconomicManager"
                :disabled="isSavingEconomicManager"
                depressed
                large
                color="indigo lighten-4 rounded-xl"
            >
              <span class="indigo--text"
              >Registrar responsable y continuar</span
              >

              <v-icon color="indigo">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <registerStudentEconomicManagerTermsAndConditionsModal
        ref="termsAndConditionsModal"
        @accept="saveStudentEconomicManager()"
        @denied="closeModal()"
        :studentEconomicManager="studentEconomicManager"
        :savingEconomicManagerState="isSavingEconomicManager"
    ></registerStudentEconomicManagerTermsAndConditionsModal>
  </v-dialog>
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";
import onlinePaymentsRepository from "@/repositories/onlinePaymentsRepository";
import {mapGetters} from "vuex";
import registerStudentEconomicManagerTermsAndConditionsModal
  from "@/view/components/modals/payments/studentEconomicManagerTermsAndConditionsModal.vue";

export default {
  name: "registerStudentEconomicManagerModal",

  mounted() {
    this.getDepartments();
  },

  props: {
    persistent: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      dialog: false,

      isSavingEconomicManager: false,

      loadingMunicipalities: false,
      loadingDepartments: false,

      departmentsList: [],
      municipalitiesList: [],

      selectedDepartment: {},
      selectedMunicipality: {},

      studentEconomicManager: {
        uuid: null,
        first_name: null,
        last_name: null,
        address: null,
        municipality: null,
        dui: null,
        cellphone: null,
        email: null,
        dui_front_photo: null,
        dui_back_photo: null,
      },
    };
  },

  components: {
    registerStudentEconomicManagerTermsAndConditionsModal,
  },

  methods: {
    closeModal() {
      this.isSavingEconomicManager = false;
      this.toggleTermsAndConditionsModal();
    },
    validateStudentEconomicManager() {

      let formattedMunicipality = {
        id: this.selectedMunicipality.id,
        idDepartamento: this.selectedDepartment.id,
        nombre: this.selectedMunicipality.name,
        departamento: {
          id: this.selectedDepartment.id,
          nombre: this.selectedDepartment.name,
          idPais: 68,
          iso31662: "null",
        },
      };

      this.studentEconomicManager.municipality = JSON.stringify(
          formattedMunicipality
      );

      this.studentEconomicManager.uuid = this.currentUserPersonalInfo.uuid;

      //checking validations
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.toggleTermsAndConditionsModal();
      } else {
        this.fireToast({
          icon: "error",
          title: "Por favor, revise los datos ingresados",
        });
      }
    },

    saveStudentEconomicManager() {
      this.isSavingEconomicManager = true;
      onlinePaymentsRepository
          .registerStudentEconomicManager(
              this.objectToFormData(this.studentEconomicManager)
          )
          .then(() => {
            this.fireToast({
              icon: "success",
              title: "Responsable económico registrado correctamente",
            });
            this.toggleModal();
            this.$emit("registeredEconomicManager");
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un problema, por favor intentar más tarde",
            });
          })
          .finally(() => {
            this.isSavingEconomicManager = false;
          });
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closedFromToolbar() {
      this.$emit("closedModal");
      this.toggleModal();
    },

    toggleTermsAndConditionsModal() {
      this.$refs.termsAndConditionsModal.toggleModal();
    },

    handleEmptySpaces(event, fieldName) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, " ");
      this.studentEconomicManager[fieldName] =
          this.studentEconomicManager[fieldName] + paste;
    },

    handleKeyUpAddress() {
      this.$v.studentEconomicManager.address.$touch();
      if (!this.$v.studentEconomicManager.address.$invalid) {
        this.verticalFormStep = 3;
      }
    },

    async getDepartments() {
      this.loadingDepartments = true;
      let errors = null;

      try {
        let response = await fetch(
            "https://apiform.ricaldone.edu.sv/api/departments"
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        this.departmentsList = await response.json();
      } catch (error) {
        errors = error.message;
        console.error(errors);
      } finally {
        this.loadingDepartments = false;
      }
    },

    async getMunicipalities(departmentId) {
      this.loadingMunicipalities = true;
      this.municipalitiesList = [];
      let errors = null;

      try {
        let response = await fetch(
            `https://apiform.ricaldone.edu.sv/api/municipalities/department/${departmentId}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        this.municipalitiesList = await response.json();
      } catch (error) {
        errors = error.message;
        console.error(errors);
      } finally {
        this.loadingMunicipalities = false;
      }
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    duiErrors() {
      const errors = [];
      if (!this.$v.studentEconomicManager.dui.$dirty) return errors;
      !this.$v.studentEconomicManager.dui.required &&
      errors.push("El campo DUI es requerido.");
      !this.$v.studentEconomicManager.dui.validDuiFormat &&
      errors.push("El DUI debe contener guión (########-#)");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.studentEconomicManager.cellphone.$dirty) return errors;
      !this.$v.studentEconomicManager.cellphone.required &&
      errors.push("El campo teléfono es requerido.");
      !this.$v.studentEconomicManager.cellphone.validPhoneNumberFormat &&
      errors.push("No debe contener guión, debe empezar con 2 o 7");
      return errors;
    },
  },

  validations: {
    selectedMunicipality: {
      required,
    },
    selectedDepartment: {
      required,
    },
    studentEconomicManager: {
      first_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      dui: {
        required,
        validDuiFormat(value) {
          const pattern = /^\d{8}-\d{1}$/;
          return pattern.test(value);
        },
      },
      cellphone: {
        required,
        validPhoneNumberFormat(value) {
          const pattern = /^(2|6|7)\d{3}\d{4}$/;
          return pattern.test(value);
        },
      },
      email: {
        required,
        email,
      },
      address: {
        required,
        maxLength: maxLength(240),
        minLength: minLength(10),
      },
      // dui_front_photo: { required, isImage },
      // dui_back_photo: { required, isImage },
    },
  },
};
</script>
