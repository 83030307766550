<template>
  <div class="d-flex flex-column flex-root">
    <!-- loading state while verifying economic manager -->
    <div v-if="verifyingEconomicManagerExistence" class="ma-0 pa-0">
      <v-row
        class="fill-height justify-center d-flex align-center py-15 mt-3 rounded-lg white"
      >
        <v-col class="text-subtitle-1 text-center" cols="12">
          <p class="text-h6 font-weight-medium text-center">
            Cargando listado de pagos..
          </p>
        </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <v-progress-circular
            :size="70"
            :width="9"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>

    <!-- Maintenance modal -->
    <div v-if="!verifyingEconomicManagerExistence">
      <GeneralInformativeModal
        ref="mdlDowntimeAlert"
        icon="alert-octagram-outline"
        theme="red"
        title="Estimada comunidad educativa"
      >
        <template v-slot:body>
          <p class="text-body-1">
            Informar que hemos extendido el periodo de mantenimiento y
            actualización de nuestros servidores, estamos trabajando para poder
            habilitar el servicio de pagos en línea nuevamente lo más pronto
            posible, esperamos poder habilitarlo a más tardar el día lunes 12 de agosto del presente año.
          </p>
          <p class="text-body-1">
            Recordar que los demás medios de pagos (pago en colecturía, pago en
            bancos autorizados con el talonario de pagos) siguén habilitados y
            funcionando correctamente.
          </p>
          <p class="text-body-1">Agradecemos por su paciencia y comprensión.</p>
        </template>
      </GeneralInformativeModal>
    </div>

    <!-- After loading and verification of economic manager has finished -->
    <div v-if="!verifyingEconomicManagerExistence">
      <div class="white rounded-lg pa-5 py-xl-8 px-xl-10 my-4">
        <v-container>
          <v-row>
            <v-col cols="12" md="9">
              <h1 class="h1 mb-8">Pagos en línea</h1>
              <!-- Student Information -->
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="d-flex flex-column justify-start align-start"
                >
                  <p class="subtitle-2 text--secondary mb-0">Estudiante</p>
                  <p class="subtitle-1 text--primary font-weight-bold mb-0">
                    {{ currentUserPersonalInfo.full_name }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex flex-column justify-start align-start"
                >
                  <p class="subtitle-2 text--secondary mb-0">Código</p>
                  <p class="subtitle-1 text--primary font-weight-bold mb-0">
                    {{ currentUserPersonalInfo.code }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex flex-column justify-start align-start"
                >
                  <p class="subtitle-2 text--secondary mb-0">Grado</p>
                  <p class="subtitle-1 text--primary font-weight-bold mb-0">
                    {{ currentUserPersonalInfo.grade }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="d-flex flex-column justify-start align-start"
                >
                  <p class="subtitle-2 text--secondary mb-0">Especialidad</p>

                  <p class="subtitle-1 text--primary font-weight-bold mb-0">
                    {{ currentUserPersonalInfo.speciality }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <!-- Payment history - action button -->
            <v-col cols="12" md="3">
              <div class="d-flex flex-column align-lg-end align-xl-center">
                <div class="ma-0 pa-0 d-flex flex-column align-center">
                  <v-avatar size="80px" color="indigo lighten-4">
                    <v-icon color="indigo" large>{{
                      paymentHistoryViewActiveState
                        ? "mdi-credit-card-fast"
                        : "mdi-credit-card-clock-outline"
                    }}</v-icon>
                  </v-avatar>

                  <v-btn
                    class="ma-2"
                    outlined
                    rounded
                    color="indigo"
                    @click="openPaymentHistoryView()"
                  >
                    {{
                      paymentHistoryViewActiveState
                        ? "Ver pagos pendientes"
                        : "Ver historial de pagos"
                    }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- Unprocessed payments -->
      <div
        class="white rounded-lg pa-5 py-md-8 px-md-10 my-4"
        v-if="unprocessedPayments.length > 0 && paymentHistoryViewActiveState"
      >
        <v-alert text class="rounded-lg mb-5" color="deep-orange">
          <div class="d-flex flex-row justify-start align-center mb-4">
            <p class="text-h5 mb-0 ml-3 font-weight-bold">
              Listado de pagos en proceso
            </p>
          </div>

          <div class="rounded-lg deep-orange lighten-5 pa-4 mb-2">
            <p class="text-h6 font-weight-normal black--text text-justify ma-0">
              Los pagos que aparezcan listados en esta sección son pagos
              realizados en línea que aún estan siendo procesados.
            </p>
          </div>
        </v-alert>
        <v-timeline dense clipped class="pt-10">
          <v-timeline-item
            v-for="(payment, index) in unprocessedPayments"
            :key="index"
            fill-dot
            color="red lighten-4"
          >
            <template v-slot:icon>
              <v-icon color="red">mdi-cash-clock</v-icon>
            </template>

            <p class="black--text text-md-h6 font-weight-normal mt-1 mb-0">
              <span class="font-weight-medium black--text text--darken-4">
                {{ payment.paymentConcept }}
              </span>
              por un monto de
              <span class="font-weight-bold success--text text--darken-1">{{
                payment.totalToPayFormatted
              }}</span>
              dólares.
            </p>
          </v-timeline-item>
        </v-timeline>
      </div>

      <div
        class="white rounded-lg pa-5 py-md-8 px-md-10 my-4"
        v-if="paymentHistoryViewActiveState"
      >
        <v-alert class="rounded-lg mb-5" color="light-blue lighten-4">
          <div class="d-flex flex-row justify-start align-center mb-4">
            <p
              class="text-h5 mb-0 ml-3 font-weight-bold light-blue--text text--darken-4"
            >
              Historial de pagos realizados
            </p>
          </div>

          <div class="rounded-lg light-blue lighten-5 pa-4 mb-2">
            <p class="text-h6 font-weight-normal black--text text-justify ma-0">
              En la siguiente lista aparecen listados unicamente los pagos
              realizados por el método de pago en línea.
            </p>
          </div>
        </v-alert>
        <v-row v-if="paymentHistory.length > 0">
          <v-col cols="12" md="8" lg="7">
            <v-timeline dense clipped class="pt-10">
              <v-timeline-item
                v-for="(payment, index) in paymentHistory"
                :key="index"
                fill-dot
                class="mb-8"
                color="light-blue lighten-4"
                large
              >
                <template v-slot:icon>
                  <v-icon color="blue">mdi-receipt-text-check-outline</v-icon>
                </template>

                <p class="black--text text-md-h6 font-weight-normal mb-1">
                  <span class="font-weight-bold black--text text--darken-4">
                    {{ payment.concepto }}
                  </span>
                  por un monto de
                  <span class="font-weight-bold success--text text--darken-1"
                    >${{ payment.total }}</span
                  >
                  dólares.
                </p>
                <p
                  class="mb-0 grey--text text--darken-3 text-body-1 font-weight-medium"
                >
                  {{
                    fullFormattedDate(
                      payment.fecha_hora,
                      "dddd D [de] MMMM [del] YYYY [a las] h:mm A"
                    )
                  }}
                </p>
                <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                  N.° de autorización:
                  <span class="font-weight-bold">{{
                    payment.numero_autorizacion
                  }}</span>
                </p>
                <p class="mb-0 grey--text text--darken-3 text-body-1 mt-1">
                  N.° de referencia:
                  <span class="font-weight-bold">{{
                    payment.numero_referencia
                  }}</span>
                </p>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <v-row v-else class="py-15" align-content="center" justify="center">
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-icon color="blue darken-1" x-large> mdi-credit-card-off </v-icon>
          </v-col>
          <v-col
            class="text-subtitle-1 text-center font-weight-medium"
            cols="12"
          >
            No se ha realizado ningún pago en línea.
          </v-col>
        </v-row>
      </div>

      <v-stepper
        v-if="!paymentHistoryViewActiveState"
        v-model="paymentStepper"
        class="my-4"
        elevation="0"
      >
        <v-stepper-header>
          <v-stepper-step :complete="paymentStepper > 1" step="1">
            Seleccionar pagos
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="paymentStepper > 2" step="2">
            Relizar pagos
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <v-row>
                <!-- begin:: Columna con items seleccionables -->
                <v-col cols="12" sm="9">
                  <v-row>
                    <v-col>
                      <v-sheet elevation="1" rounded="lg" class="p-4 mb-3">
                        <h2 class="h2">Listado de pagos pendientes</h2>
                        <p>Selecciona los pagos que deseas realizar</p>

                        <!-- begin:: Fetching pending payments loader -->
                        <v-container
                          v-if="isLoadingPendingPayments"
                          style="height: 30vh"
                        >
                          <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                          >
                            <v-col
                              class="text-subtitle-1 text-center"
                              cols="12"
                            >
                              Cargando pagos pendientes
                            </v-col>
                            <v-col cols="6">
                              <v-progress-linear
                                color="blue darken-1"
                                indeterminate
                                rounded
                                height="6"
                              ></v-progress-linear>
                            </v-col>
                          </v-row>
                        </v-container>
                        <!-- end:: Fetching pending payments loader -->

                        <v-container
                          v-else-if="
                            isLoadingPendingPayments == false &&
                            pendingPayments.length == 0
                          "
                          style="height: 30vh"
                        >
                          <v-row
                            class="fill-height"
                            align-content="center"
                            justify="center"
                          >
                            <v-col
                              cols="12"
                              class="d-flex justify-center align-center"
                            >
                              <v-icon color="blue darken-1" x-large>
                                mdi-credit-card-off
                              </v-icon>
                            </v-col>
                            <v-col
                              class="text-subtitle-1 text-center font-weight-medium"
                              cols="12"
                            >
                              No hay ningún pago pendiente
                            </v-col>
                          </v-row>
                        </v-container>

                        <div v-else>
                          <!-- begin:: Encabezado de lista de items -->
                          <v-sheet
                            class="d-none d-sm-flex flex-row align-center"
                          >
                            <v-container>
                              <v-row no-gutters>
                                <v-col
                                  class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                                  sm="1"
                                >
                                  Seleccionar
                                </v-col>
                                <v-col
                                  class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                                  sm="4"
                                >
                                  Concepto
                                </v-col>
                                <v-col
                                  class="d-flex justify-center align-center text-center pa-0 ma-0 font-weight-medium"
                                  sm="2"
                                >
                                  Fecha vencimiento
                                </v-col>
                                <v-col
                                  class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                                  sm="2"
                                >
                                  Monto
                                </v-col>
                                <v-col
                                  class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium"
                                  sm="2"
                                >
                                </v-col>
                                <v-col
                                  class="d-flex justify-center align-center pa-0 ma-0 font-weight-medium text-center"
                                  sm="1"
                                >
                                  Total a pagar
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-sheet>
                          <!-- end:: Encabezado de lista de items -->

                          <!-- begin:: listado de items -->
                          <v-hover
                            v-if="!maintenanceMode"
                            v-slot="{ hover }"
                            v-for="(item, index) in pendingPayments"
                            :key="item.id"
                          >
                            <v-sheet
                              :elevation="hover ? 5 : 0"
                              class="d-flex flex-column flex-sm-row align-md-center mb-2 selected"
                              :color="`grey lighten-${index % 2 ? 3 : 5}`"
                              rounded="xl"
                            >
                              <v-container class="py-0">
                                <v-row no-gutters>
                                  <v-col
                                    cols="12"
                                    sm="1"
                                    class="d-flex justify-center align-center"
                                  >
                                    <v-checkbox
                                      :input-value="
                                        selectedPayments.indexOf(item) != -1
                                      "
                                      @change="
                                        (isChecked) =>
                                          toggleItem(isChecked, item)
                                      "
                                      :disabled="!canBeChecked(item)"
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="4"
                                    class="d-flex justify-center justify-sm-start align-center font-weight-medium pr-sm-3 pb-2"
                                  >
                                    <p class="m-0 text-justify">
                                      {{ item.paymentConcept }}
                                    </p>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="2"
                                    class="d-flex justify-center justify-sm-center align-center text-center pb-2"
                                  >
                                    {{ item.dueDate }}
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="2"
                                    class="d-flex justify-center align-center text-body-1 mb-2 mb-sm-0"
                                  >
                                    <div>
                                      {{ item.chargedPayment }}
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="2"
                                    class="d-flex justify-center align-center text-body-1 mb-2 mb-sm-0"
                                  >
                                    <div>
                                      {{ item.surchargeFormatted }}
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="1"
                                    class="d-flex justify-center align-center font-weight-medium pa-0 ma-0 pb-3"
                                  >
                                    {{ item.totalToPayFormatted }}
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-sheet>
                          </v-hover>

                          <v-container v-if="maintenanceMode">
                            <v-row
                              class="rounded-lg pa-3 grey lighten-4 d-none"
                            >
                              <v-col cols="12">
                                <p class="text-h5">
                                  Estimada comunidad educativa
                                </p>
                                <p class="text-justify text-body-1">
                                  La presente es para
                                  <span class="font-weight-bold"
                                    >informar que el día 9 de agosto a partir de
                                    las 8:00 a.m. en adelante los pagos en línea
                                    de mensualidades estarán deshabilitados
                                    hasta finalizar el día</span
                                  >, ya que nuestros servidores estarán en un
                                  periodo de mantenimiento. Cabe aclarar que las
                                  demás opciones de pago estarán disponibles y
                                  en el Portal Estudiantil aún será posible
                                  descargar el talonario de pago con normalidad.
                                </p>
                                <p class="text-justify text-body-1">
                                  Si se desea realizar el pago en línea
                                  esperamos restablecer el funcionamiento antes
                                  de finalizar el día, por lo que sugerimos
                                  estar pendientes de la plataforma.
                                </p>
                                <p class="text-justify text-body-1">
                                  Agradecemos su compresión.
                                </p>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-alert
                                border="top"
                                colored-border
                                type="info"
                                elevation="2"
                              >
                                <h1>¡Atención!</h1>
                                <p class="text-body-1">
                                  Estimada comunidad educativa:
                                </p>
                                <p class="text-body-1">
                                  Informar que hemos extendido el periodo de
                                  mantenimiento y actualización de nuestros
                                  servidores, estamos trabajando para poder
                                  habilitar el servicio de pagos en línea
                                  nuevamente lo más pronto posible, esperamos poder habilitarlo a más tardar el día lunes 12 de agosto del presente año.
                                </p>
                                <p class="text-body-1">
                                  Recordar que los demás medios de pagos (pago
                                  en colecturía, pago en bancos autorizados con
                                  el talonario de pagos) siguén habilitados y
                                  funcionando correctamente.
                                </p>
                                <p class="text-body-1">
                                  Agradecemos por su paciencia y comprensión.
                                </p>
                              </v-alert>
                            </v-row>
                          </v-container>

                          <!-- end:: listado de items -->
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- end:: Columna con items seleccionables -->

                <!-- begin:: Columna con resumen de pagos seleccionados -->
                <v-col cols="12" sm="3">
                  <v-sheet elevation="1" rounded="lg" class="p-3 mb-3">
                    <h3>Pagos seleccionados</h3>
                    <div
                      v-if="selectedPayments.length == 0"
                      class="w-full blue-grey lighten-5 text-center rounded px-2 py-1"
                    >
                      <p class="m-0 text-body-1 font-semibold">
                        Ningún pago seleccionado
                      </p>
                    </div>
                    <!-- begin::Card de item seleccionado -->
                    <div
                      v-else
                      v-for="(item, index) in selectedPayments"
                      :key="index"
                      class="d-flex flex-column justify-center align-start flex-lg-row justify-lg-start align-lg-center blue-grey lighten-5 rounded px-2 py-1 my-2"
                    >
                      <p class="my-0">
                        {{ item.paymentConcept }}
                      </p>
                      <h6 class="ml-1">
                        {{ item.totalToPayFormatted }}
                      </h6>
                    </div>
                    <!-- end::Card de item seleccionado -->
                    <v-divider></v-divider>
                    <!-- total a pagar -->
                    <div
                      class="d-flex flex-row justify-space-between align-center"
                    >
                      <h6 class="text--secondary">Total a pagar</h6>
                      <span class="font-weight-bold text-h6 text--primary">{{
                        amountToPayFormatted
                      }}</span>
                    </div>
                  </v-sheet>
                  <!-- btn pagar -->
                  <v-btn
                    block
                    color="primary"
                    @click="switchToStepTwo"
                    :disabled="numberOfSelectedPayments == 0"
                    >Siguiente paso</v-btn
                  >
                </v-col>
                <!-- end:: Columna con resumen de pagos seleccionados -->
              </v-row>
            </v-container>
            <!-- <v-btn color="primary"> Continue </v-btn>

          <v-btn text> Cancel </v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-btn
              color="light-blue lighten-5"
              text
              class="mb-4 blue--text text--darken-4 mx-3"
              @click="paymentStepper = 1"
            >
              <v-icon class="mr-1"> mdi-arrow-left-bold-circle-outline </v-icon>
              Paso anterior
            </v-btn>
            <v-container>
              <v-row>
                <v-col cols="12" sm="8" order="2" order-sm="1">
                  <v-sheet elevation="1" rounded="lg" class="px-4 py-3">
                    <h1>Detalles del pago</h1>
                    <v-alert dense text type="info" class="px-3 py-2">
                      Animación con fines ilustrativos
                    </v-alert>
                    <v-col sm="12" md="10" lg="6">
                      <CreditCard
                        :ccvCode="cardDetails.tarjeta_cvv"
                        :cardHolder="cardDetails.nombre"
                        :cardNumber="cardDetails.tarjeta_numero"
                        :expirationDate="cardDetails.tarjeta_vencimiento"
                      />
                    </v-col>
                    <v-form ref="paymentForm">
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <form-group
                              name="titular de la tarjeta"
                              :validator="$v.cardDetails.nombre"
                            >
                              <v-text-field
                                slot-scope="{ attrs }"
                                v-bind="attrs"
                                v-model="cardDetails.nombre"
                                label="Titular de la tarjeta"
                                placeholder="Titular de la tarjeta"
                                required
                                filled
                                rounded
                                dense
                              ></v-text-field>
                            </form-group>
                          </v-col>

                          <v-col cols="12">
                            <form-group
                              name="número de tarjeta"
                              :validator="$v.cardDetails.tarjeta_numero"
                            >
                              <v-text-field
                                slot-scope="{ attrs }"
                                v-bind="attrs"
                                v-mask="'#### #### #### ####'"
                                v-model="cardDetails.tarjeta_numero"
                                label="Número de tarjeta"
                                placeholder="Número de tarjeta"
                                required
                                filled
                                rounded
                                dense
                              ></v-text-field>
                            </form-group>
                          </v-col>

                          <v-col cols="6">
                            <form-group
                              name="fecha de vencimiento"
                              :validator="$v.cardDetails.tarjeta_vencimiento"
                            >
                              <v-text-field
                                slot-scope="{ attrs }"
                                v-bind="attrs"
                                v-mask="'##/##'"
                                v-model="cardDetails.tarjeta_vencimiento"
                                label="Fecha de vencimiento (mm/yy)"
                                placeholder="Fecha de vencimiento (mm/yy)"
                                required
                                filled
                                rounded
                                dense
                              ></v-text-field>
                            </form-group>
                          </v-col>

                          <v-col cols="6">
                            <form-group
                              name="código de seguridad"
                              :validator="$v.cardDetails.tarjeta_cvv"
                            >
                              <v-text-field
                                slot-scope="{ attrs }"
                                v-bind="attrs"
                                v-mask="'###'"
                                v-model="cardDetails.tarjeta_cvv"
                                label="Código de seguridad CVV"
                                placeholder="Código de seguridad CVV"
                                required
                                filled
                                rounded
                                dense
                              ></v-text-field>
                            </form-group>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="d-flex flex-column justify-center align-center"
                          >
                            <Recaptcha
                              ref="recaptcha"
                              @verificationFunction="recaptchaVerification"
                            />
                            <span class="mt-2" v-if="!recaptchaVerified"
                              >Debe de verificar el recaptcha para realizar el
                              pago</span
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-btn
                              :disabled="!unlockConfirmPayButton"
                              color="success"
                              block
                              :loading="isPayBeingProcessed"
                              @click="submit"
                            >
                              <v-icon class="mr-1"
                                >mdi-credit-card-outline</v-icon
                              >
                              Confirmar pago
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4" order="1" order-sm="2">
                  <v-sheet elevation="1" rounded="lg" class="p-3 mb-3">
                    <h3>Pagos seleccionados</h3>
                    <!-- begin::Card de item seleccionado -->
                    <div
                      v-for="(item, index) in selectedPayments"
                      :key="index"
                      class="d-flex flex-column justify-center align-start flex-lg-row justify-lg-start align-lg-center blue-grey lighten-5 rounded px-2 py-1 my-2"
                    >
                      <p class="my-0">
                        {{ item.paymentConcept }}
                      </p>
                      <h6 class="ml-1">
                        {{ item.totalToPayFormatted }}
                      </h6>
                    </div>
                    <!-- end::Card de item seleccionado -->
                    <v-divider></v-divider>
                    <!-- total a pagar -->
                    <div
                      class="d-flex flex-row justify-space-between align-center"
                    >
                      <h6 class="text--secondary">Total a pagar</h6>
                      <span class="font-weight-bold text-h6 text--primary">{{
                        amountToPayFormatted
                      }}</span>
                    </div>
                  </v-sheet>

                  <v-sheet elevation="1" rounded="lg" class="p-3 mb-3">
                    <p class="m-0">
                      El <strong>Instituto Técnico Ricaldone</strong> en alianza
                      con <strong>SERFINSA</strong>, implementa el método de
                      Pagos Online.
                    </p>
                    <div
                      class="d-flex flex-column justify-start align-center mt-2"
                    >
                      <div>
                        <img
                          src="media/logos/logo-visa-master.png"
                          alt="Logo visa y mastercard"
                          class="img-fluid"
                          style="height: 25px"
                        />
                        <img
                          src="media/logos/logo-union-pay.png"
                          alt="Logo serfinsa"
                          class="img-fluid"
                          style="height: 30px"
                        />
                      </div>
                      <img
                        src="media/logos/logo-serfinsa.png"
                        alt="Logo serfinsa"
                        class="img-fluid mt-1"
                        style="height: 30px"
                      />
                    </div>
                  </v-sheet>

                  <v-sheet elevation="1" rounded="lg" class="p-3 mb-3">
                    <h6>Información de responsable económico</h6>
                    <p class="m-0 subtitle-1">
                      {{
                        `${studentEconomicManager.first_name} ${studentEconomicManager.last_name}`
                      }}
                    </p>
                    <p class="m-0 subtitle-1">
                      {{ studentEconomicManager.dui }}
                    </p>
                    <p class="m-0 subtitle-1">
                      {{ studentEconomicManager.email }}
                    </p>
                    <p class="m-0 subtitle-1">
                      {{ studentEconomicManager.address }}
                    </p>
                    <p class="m-0 subtitle-1">
                      {{
                        `${studentEconomicManager.municipality?.nombre}, ${studentEconomicManager.municipality?.departamento?.nombre}`
                      }}
                    </p>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <registerStudentEconomicManagerModalVue
      ref="registerStudentEconomicManagerModal"
      @registeredEconomicManager="loadPayments()"
      @closedModal="closedModalWithoutRegister()"
      :persistent="true"
    ></registerStudentEconomicManagerModalVue>
  </div>
</template>

<script>
import GeneralInformativeModal from "@/view/components/modals/agnostic/GeneralInformativeModal";

import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Recaptcha from "@/view/components/payments/Recaptcha";
import CreditCard from "@/view/components/payments/CreditCard";
import Swal from "sweetalert2";
import registerStudentEconomicManagerModalVue from "../../components/modals/payments/registerStudentEconomicManagerModal.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import onlinePaymentsRepository from "@/repositories/onlinePaymentsRepository";

import {
  normalizeFloat,
  formatPayments,
  sortPayments,
  linkPayments,
  removeZeroCostPayments,
} from "@/core/utils/handlePayments";

import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

export default {
  name: "OnlinePayments",
  title: "Pagos en línea | PE ITR",
  components: {
    Recaptcha,
    CreditCard,
    registerStudentEconomicManagerModalVue,
    GeneralInformativeModal,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pagos en línea", route: "online-payments" },
    ]);

    this.deleteSelectedPayments();
  },
  created() {
    this.getStudentEconomicManager();
  },

  data() {
    return {
      verifyingEconomicManagerExistence: true,
      isLoadingPendingPayments: false,
      pendingPayments: [],
      selectedPayments: [],
      unprocessedPayments: [],

      studentEconomicManager: {
        first_name: "",
        last_name: "",
        dui: "",
        email: "",
        address: "",
      },

      paymentHistoryViewActiveState: false,

      paymentStepper: 1,

      cardDetails: {
        nombre: "",
        tarjeta_numero: "",
        tarjeta_vencimiento: "",
        tarjeta_cvv: "",
      },

      paymentHistory: [],

      isPayBeingProcessed: false,

      recaptchaVerified: false,

      formCompleted: false,

      maintenanceMode: false,
    };
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    studentCode() {
      return this.currentUserPersonalInfo.code;
    },
    numberOfSelectedPayments() {
      return this.selectedPayments.length;
    },
    canBeChecked() {
      // Se llama la variable computada de esta forma para que el callback retornado sea reactivo
      this.numberOfSelectedPayments;

      // Se retorna un callback que se ejecuta cada vez que el número de pagos seleccionados cambia, de esta forma cuando ese array pagos seleccionados se modifica cada checkbo ejecuta esta funcion y verifica si puede ser checkeado o no
      return (item) => {
        // Verificando si tiene la key "prevItemCorrelative" con el correlativo que le antecede (si no significa que es el primer elemento de la lista)
        if (item.prevItemCorrelative == undefined) {
          return true;
        }

        // Verificando si encuentra un item con el correlativo que le antecede al item
        const foundedPreviousItem = this.selectedPayments.some(
          (ele) => ele.paymentCorrelative == item.prevItemCorrelative
        );

        return foundedPreviousItem;
      };
    },
    amountToPay() {
      let total = 0;
      for (const item of this.selectedPayments) {
        total += item.totalToPay;
      }
      return total;
    },

    amountToPayFormatted() {
      return this.toUSDFormat(this.amountToPay);
    },
    unlockConfirmPayButton() {
      return this.recaptchaVerified && this.formCompleted;
    },
  },

  methods: {
    normalizeFloat,
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.processPayment();
    },

    showMdlDowntimeAlert() {
      this.$refs.mdlDowntimeAlert.toggleModal();
    },

    closedModalWithoutRegister() {
      this.$refs.registerStudentEconomicManagerModal.toggleModal();
      this.$router.push({
        path: "view_subjects_modules",
      });
    },

    loadPayments() {
      this.verifyingEconomicManagerExistence = false;
    },
    getStudentEconomicManager() {
      onlinePaymentsRepository
        .registeredStudentEconomicManager(this.currentUserPersonalInfo.uuid)
        .then(({ data }) => {
          this.studentEconomicManager = data;

          // if (Object.keys(this.studentEconomicManager).length === 0) {
          //   this.openRegisterStudentEconomicManagerModal();
          // } else {
          this.verifyingEconomicManagerExistence = false;
          // }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se pudo traer al responsable económico",
          });
        })
        .finally(() => {
          // this.isSavingEconomicManager = false;
        });
    },

    openRegisterStudentEconomicManagerModal() {
      this.$refs.registerStudentEconomicManagerModal.toggleModal();
    },

    processPayment() {
      this.isPayBeingProcessed = true;
      onlinePaymentsRepository
        .getTotal(this.studentCode)
        .then(({ data }) => {
          Swal.fire({
            title: "Confirmación",
            text:
              "¿Desea realizar el pago correspondiente a $" +
              data +
              " dólares?",
            type: "info",
            showCancelButton: true,
            confirmButtonColor: "#28A745",
            confirmButtonText: "Realizar pago",
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: false,
          }).then((result) => {
            if (result.isConfirmed) {
              // Formatting payload
              let monto = "000000000000";
              let amountStr = data.replace(".", "");
              monto =
                monto.substr(0, monto.length - amountStr.length) + amountStr;

              let tarjeta_numero = this.cardDetails.tarjeta_numero.replace(
                /\s/g,
                ""
              );

              let tarjeta_vencimiento =
                this.cardDetails.tarjeta_vencimiento.split("/");
              tarjeta_vencimiento =
                tarjeta_vencimiento[1] + tarjeta_vencimiento[0];

              let tarjeta_cvv = "1611 " + this.cardDetails.tarjeta_cvv;

              // Creating payload
              const payload = {
                monto,
                nombre: this.cardDetails.nombre,
                tarjeta_numero,
                tarjeta_vencimiento,
                tarjeta_cvv,
                codigo: this.studentCode,
              };

              // TODO: Remover return que evita que se procese el pago
              // console.log(payload);
              // return;

              // API call for payment processing
              onlinePaymentsRepository
                .makePayment(payload)
                .then(({ data }) => {
                  if (data == true || data == "true") {
                    // TODO: clean card illustration
                    this.$refs.paymentForm.reset();
                    this.$v.$reset();
                    this.loadPaymentsHistory();
                    this.loadPendingPayments();
                    this.paymentStepper = 1;

                    Swal.fire(
                      "¡Pago realizado correctamente!",
                      "La transacción se verá reflejada en un periodo de 24 horas en el reporte de estados de cuenta.",
                      "success"
                    );
                  } else if (data == "TIEMPO") {
                    Swal.fire(
                      "¡Atención!",
                      "La transacción demoró más de lo esperado, no se realizó ningún cobro a la tarjeta ingresada.",
                      "info"
                    );
                  } else {
                    Swal.fire("¡Atención!", data, "info");
                  }
                })
                .catch((err) => {
                  if (err.responseText == "TIEMPO") {
                    Swal.fire(
                      "¡Atención!",
                      "La transacción demoró más de lo esperado, no se realizó ningún cobro a la tarjeta ingresada.",
                      "info"
                    );
                  } else {
                    Swal.fire("¡Atención!", err.responseText, "info");
                  }

                  if (process.env.APP_ENV != "production") {
                    console.error(
                      "Error al intentar procesar el pago: ",
                      err.message
                    );
                  }
                });
            }
          });
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Ocurrio un error en la transacción y esta no fue procesada",
          });
        })
        .finally(() => {
          this.isPayBeingProcessed = false;
          // Se refresca el Turnstile para que el usuario pueda volver a intentarlo
          this.$refs.recaptcha.reload();
        });
    },

    deleteSelectedPayments() {
      if (this.studentCode == null) {
        return;
      }

      onlinePaymentsRepository
        .deleteSelectedPayments(this.studentCode)
        .then((result) => {
          if (result.data === false) {
            console.log("API response: Consulta retorno false?");
          }
        })
        .catch((err) => {
          console.error(
            "Error en la petición para eliminar todos los pagos seleccionados del detalle del pago: " +
              err
          );
        });
    },

    async loadPendingPayments() {
      if (this.studentCode == null) {
        return;
      }

      this.isLoadingPendingPayments = true;
      this.pendingPayments = [];
      this.selectedPayments = [];

      const pendingPaymentsOf2023 =
        await this.getPendingPaymentsOfStudentByYear(this.studentCode, 2023);
      const pendingPaymentsOf2024 =
        await this.getPendingPaymentsOfStudentByYear(this.studentCode, 2024);

      const pendingPaymentsOfAllYears = [
        ...pendingPaymentsOf2023,
        ...pendingPaymentsOf2024,
      ];
      const paymentsOfAllYearsWithoutUnprocessed =
        pendingPaymentsOfAllYears.filter(
          (item) => item.PAGO_SIN_FACTURAR != true
        );
      const formattedPaymentsOfAllYears = formatPayments(
        paymentsOfAllYearsWithoutUnprocessed
      );
      const sortedPaymentsOfAllYears = sortPayments(
        formattedPaymentsOfAllYears
      );
      const paymentsWithoutZeroCost = removeZeroCostPayments(
        sortedPaymentsOfAllYears
      );
      const linkedPaymentsOfAllYears = linkPayments(paymentsWithoutZeroCost);
      this.pendingPayments = linkedPaymentsOfAllYears;

      const unprocessedPaymentsOfAllYears = pendingPaymentsOfAllYears.filter(
        (item) => item.PAGO_SIN_FACTURAR == true
      );
      const formattedUnprocessedPaymentsOfAllYears = formatPayments(
        unprocessedPaymentsOfAllYears
      );
      const sortedUnprocessedPaymentsOfAllYears = sortPayments(
        formattedUnprocessedPaymentsOfAllYears
      );
      const linkedUnprocessedPaymentsOfAllYears = linkPayments(
        sortedUnprocessedPaymentsOfAllYears
      );
      this.unprocessedPayments = linkedUnprocessedPaymentsOfAllYears;

      this.isLoadingPendingPayments = false;
    },

    getPendingPaymentsOfStudentByYear(studentCode, year) {
      return new Promise((resolve) => {
        const payload = {
          studentCode: studentCode,
          yearToConsult: year,
        };

        onlinePaymentsRepository
          .getAllPendingPayments(payload)
          .then((result) => {
            resolve(result.data);
          })
          .catch((err) => {
            console.error(
              "Error en la petición para cargar pagos pendentes: " + err.message
            );
            resolve([]);
          });
      });
    },
    openPaymentHistoryView() {
      this.paymentHistoryViewActiveState = !this.paymentHistoryViewActiveState;
    },

    loadPaymentsHistory() {
      if (this.studentCode == null) {
        return;
      }

      this.paymentHistory = [];

      onlinePaymentsRepository
        .getPaymentsHistory(this.studentCode)
        .then((result) => {
          this.paymentHistory = result.data;
        })
        .catch((err) => {
          console.error(
            "Error en la petición para cargar historial de pagos: " +
              err.message
          );
        });
    },

    toggleItem(isChecked, item) {
      if (isChecked) {
        this.selectedPayments.push(item);
        this.addSelectedPaymentToPaymentDetail(item);
      } else {
        const idxOfUnselectedItem = this.selectedPayments.indexOf(item);
        const paymentsToBeRemoved = this.selectedPayments.slice(
          idxOfUnselectedItem,
          this.selectedPayments.length
        );
        this.selectedPayments = this.selectedPayments.slice(
          0,
          idxOfUnselectedItem
        );
        this.removeUnselectedPaymentsFromPaymentDetail(paymentsToBeRemoved);
      }
    },

    addSelectedPaymentToPaymentDetail(payment) {
      onlinePaymentsRepository
        .addPayment({
          concepto: payment.paymentConcept,
          monto: payment.totalToPay,
          correlativo: payment.paymentCorrelative,
          code: this.studentCode,
        })
        .then((result) => {
          if (result.data != true) {
            console.log("API Response: Pago no seleccionado: " + result.data);
          }
        })
        .catch((err) => {
          console.log(
            "Error en la petición de seleccionar pago: " + err.message
          );
        });
    },

    removeUnselectedPaymentsFromPaymentDetail(payments) {
      const requests = payments.map((payment) => {
        return onlinePaymentsRepository.deletePayment({
          concepto: payment.paymentConcept,
          code: this.studentCode,
        });
      });

      Promise.all(requests)
        .then((responses) => {
          // All requests have completed successfully
          for (const response of responses) {
            if (response.data == false) {
              console.log(
                "API Response: Error al eliminar un pago del detalle de pagos"
              );
            }
          }
        })
        .catch((error) => {
          // At least one request has failed
          console.error(
            "Error en la petición de eliminar pago del detalle de pagos: " +
              error.message
          );
        });
    },

    // Function to validate if recaptcha was passed
    recaptchaVerification() {
      this.recaptchaVerified = true;
    },

    switchToStepTwo() {
      // Validando si se ha seleccionado cursillo que también se haya seleccionado complemento de matricula
      const idxCursilloItem = this.selectedPayments.findIndex(
        (item) => item.concepto == "CURSILLO"
      );
      if (idxCursilloItem != -1) {
        const idsSinMatricula = [
          20240634, 20240660, 20240640, 20240567, 20240179,
        ];
        const existeEnElAnteriorArray = idsSinMatricula.some((id) => {
          return id == this.studentCode;
        });
        if (existeEnElAnteriorArray) {
          this.paymentStepper = 2;
          return;
        }
        const idxComplementoItem = this.selectedPayments.findIndex(
          (item) =>
            item.concepto == "COMPLEMENTO" || item.concepto == "MATRICULA"
        );
        if (idxComplementoItem == -1) {
          Swal.fire(
            "¡Atención!",
            "Recuerda que el pago del cursillo y complemento de matrícula deben realizarse en un mismo pago, asegurate de seleccionar ambos",
            "info"
          );
          return;
        }
      }
      this.paymentStepper = 2;
    },
  },

  watch: {
    currentUserPersonalInfo(newV, oldV) {
      if (!!this.currentUserPersonalInfo.code) {
        this.loadPendingPayments();
        this.loadPaymentsHistory();
        this.deleteSelectedPayments();
      }
    },
    cardDetails: {
      handler(newValue) {
        this.formCompleted = Object.values(this.cardDetails).every(
          (value) => value != ""
        );
      },
      deep: true,
    },

    // Descomentar si esta en modo de mantenimiento
    // isLoadingPendingPayments(newV) {
    //   if (!newV) {
    //     this.showMdlDowntimeAlert();
    //   }
    // },
  },

  validations: {
    cardDetails: {
      nombre: {
        required,
      },
      tarjeta_numero: {
        required,
      },
      tarjeta_vencimiento: { required },
      tarjeta_cvv: { required },
    },
  },
};
</script>

<style scoped>
.selected {
  border: 3px solid #2196f3 !important;
}
</style>
