import Axios from "axios"
import ApiService from "@/core/services/api.service";

export const registerStudentEconomicManager = (payload) => {
    return ApiService.post("economic-managers", payload);
};

export const registeredStudentEconomicManager = (studentUUID) => {
    return ApiService.get("economic-managers", studentUUID)
}

// SGE API

const SGE_BASE_URL = "https://sge.ricaldone.edu.sv"

const SGE_PAYMENTS_ENDPOINT = `${SGE_BASE_URL}/publico/php/pago_externo.php`

const currentYear = new Date().getFullYear();


export const deleteSelectedPayments = (studentCode) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'eliminarSeleccionados',
    codigo: studentCode,
})

export const makePayment = (payload) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'pago',
    monto: payload.monto,
    nombre: payload.nombre,
    tarjeta_numero: payload.tarjeta_numero,
    tarjeta_vencimiento: payload.tarjeta_vencimiento,
    tarjeta_cvv: payload.tarjeta_cvv,
    codigo: payload.codigo,
})

export const addPayment = (payload) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'agregar',
    concepto: payload.concepto,
    monto: payload.monto,
    correlativo: payload.correlativo,
    codigo: payload.code,
});


export const deletePayment = (payload) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'eliminar',
    concepto: payload.concepto,
    codigo: payload.code,
});
 
export const getPaymentsHistory = (studentCode) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'historial',
    codigo: studentCode,
})

export const getTotal = (studentCode) => Axios.post(SGE_PAYMENTS_ENDPOINT, {
    accion: 'total',
    codigo: studentCode,
})


// CONTA API

const CONTA_API_URL = "https://contapi.ricaldone.edu.sv/public/api"

const ONLINE_PAYMENTS_RESOURCE = 'student-projection-payments'

/**
 * This API call retrieves both pending and paid payments of the specified student
 * If year is not specified it will consult the current year
 */
export const getAllPayments = ({ studentCode, yearToConsult }) => Axios.get(`${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/all/${yearToConsult || currentYear}/${studentCode}`)

/**
 * This API call retrieves pending payments of the specified student
 * If year is not specified it will consult the current year
 */

// TODO: Descomentar y comentar el de abajo en caso no funcione
// export const getAllPendingPayments = ({ studentCode, yearToConsult }) => Axios.get(`${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/not-paid/${yearToConsult || currentYear}/${studentCode}`)
export const getAllPendingPayments = ({ studentCode, yearToConsult }) => Axios.get(`${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/not-paid-check-status/${yearToConsult || currentYear}/${studentCode}`)

/**
 * This API call retrieves paid payments of the specified student
 * If year is not specified it will consult the current year
 */
export const getAllPaidPayments = ({ studentCode, yearToConsult }) => Axios.get(`${CONTA_API_URL}/${ONLINE_PAYMENTS_RESOURCE}/paid/${yearToConsult || currentYear}/${studentCode}`)

export default {
    deleteSelectedPayments,
    makePayment,
    getPaymentsHistory,
    getAllPayments,
    getAllPendingPayments,
    getAllPaidPayments,
    getTotal,
    registerStudentEconomicManager,
    registeredStudentEconomicManager,
    addPayment,
    deletePayment,
}