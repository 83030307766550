const meses = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
];

// En base al orden de esta lista de categorias son ordenados los pagos
const conceptosOrdenados = [
    "RESERVA",
    "CURSILLO",
    "CURSILLO ANTIGUO INGRESO",
    "MATRICULA",
    "COMPLEMENTO",
    "COLEGIATURA",
    "OTRO",
];

/**
 * Función que recibe lista de pagos y le da formato a ciertos campos para que tengan el tipo de dato correcto o puedan ser mostrados y remueve campos innecesarios
 * @param {*} payments 
 * @returns 
 */
export function formatPayments(payments) {
    const formattedPayments = [];

    for (const item of payments) {
        const paymentConcept = formatPaymentConceptDescription(
            item.MES,
            item.ARTICULO,
            item.EJERCICIO
        );

        const totalToPay = calculateTotalToPay(
            item.VALOR_PAGAR,
            item.VALOR_RECARGO,
            item.MONTO_DESC_BECA,
            item.MONTO_DESC_HIJOS,
            item.MONTO_DESC_OTROS
        );

        const concepto = formatConcept(item.ARTICULO);

        const dueDate = formatDueDate(item.FECHA_VENCIMIENTO, concepto)

        const surcharge = formatRecargo(item.VALOR_RECARGO)

        formattedPayments.push({
            id: paymentConcept.replace(/\s/g, "_"),
            paymentCorrelative: item.CORRELATIVO_PROY_PAGO,
            paymentConcept,
            chargedPayment: toUSDFormat(item.VALOR_PAGAR),
            totalToPay,
            totalToPayFormatted: toUSDFormat(totalToPay),
            surcharge,
            surchargeFormatted: (surcharge != "Ningún cargo aplicado") ? toUSDFormat(surcharge) : surcharge,
            dueDate,
            mes: item.MES,
            concepto,
            ejercicio: item.EJERCICIO,
        });
    }

    return formattedPayments
}

export function removeZeroCostPayments(payments) {
    const filteredPayments = [];
    for (const item of payments) {
        if (item.totalToPay == 0) {
            continue;
        }
        filteredPayments.push(item);
    }
    return filteredPayments;
}

/**
 * Funcíón que recibe lista de pagos previamente formateados, y los ordena por las categorias del pago y por mes consecutivamente
 * @param {*} payments 
 * @returns 
 */
export function sortPayments(payments) {
    return payments.sort(comparePaymentsConcept)
}


/**
 * Función que recibe lista de pagos previamente formateados y ordenados para relacionar cada pago con el anterior
 * @param {*} payments 
 * @returns 
 */
export function linkPayments(payments) {
    const linkedPendingPayments = [];
    for (let i = 0; i < payments.length; i++) {
        if (i == 0) {
            linkedPendingPayments.push(payments[i]);
            continue;
        }
        const item = payments[i];
        const prevItem = payments[i - 1];

        item.prevItemCorrelative = prevItem.paymentCorrelative;
        linkedPendingPayments.push(item);
    }
    return linkedPendingPayments;
}

function formatRecargo(recargo) {
    return recargo != null
        ? normalizeFloat(recargo)
        : "Ningún cargo aplicado";
}

function formatConcept(concepto) {
    let resultado = "";
    if (
        concepto == "RESERVA             " ||
        concepto == "CURSILLO            "
    ) {
        resultado = resultado = concepto.trim();
    } else if (concepto == "CURSILLO ANTI       " || concepto == "CURSILLO ANTI") {
        resultado == "CURSILLO ANTIGUO INGRESO"
    } else if (
        concepto == "COMPLEMENTO         " ||
        concepto == "COMP BACH           "
    ) {
        resultado = "COMPLEMENTO"
    } else if (
        concepto == "MATR I              " ||
        concepto == "MATR II             " ||
        concepto == "MATR III            " ||
        concepto == "MATRICULA           "
    ) {
        resultado = "MATRICULA";
    } else if (concepto == "COLEGIATURA III CICLO" || concepto == "COLEGIATURA         " || concepto ==
        "COLEG III CICLO     ") {
        resultado = "COLEGIATURA";
    } else {
        resultado = "OTRO"
    }
    return resultado;
}

export function normalizeFloat(str) {
    return str != null ? parseFloat(str) : parseFloat(0.0);
}

function formatPaymentConceptDescription(numeroMes, concepto, año) {
    let resultado = "";
    if (!isNaN(numeroMes) && numeroMes >= 1 && numeroMes <= 12) {
        if (concepto == "RESERVA             ") {
            resultado =
                "PAGO DE " +
                concepto +
                "DE MATRICULA CORRESPONDIENTE AL AÑO " +
                año;
        } else if (concepto == "CURSILLO            " || concepto == "CURSILLO ANTI       " || concepto == "CURSILLO ANTI") {
            resultado = "PAGO DE " + concepto + " INDUCCIÓN TÉCNICA " + año;
        } else if (concepto == "COMPLEMENTO         " || concepto == "COMP BACH           ") {
            resultado = "PAGO DE COMPLEMENTO DE MATRÍCULA " + año;
        } else if (
            concepto == "MATR I              " ||
            concepto == "MATR II             " ||
            concepto == "MATR III            " ||
            concepto == "MATRICULA           "
        ) {
            resultado = "PAGO DE MATRICULA " + año;
        } else {
            resultado =
                "PAGO DE " +
                concepto +
                "CORRESPONDIENTE AL MES DE " +
                meses[numeroMes - 1] +
                " " +
                año;
        }
    }
    return resultado;
}

function formatDueDate(dueDate, paymentConcept) {
    if (paymentConcept == "CURSILLO" || paymentConcept == "COMPLEMENTO" || paymentConcept == "RESERVA" || paymentConcept == "MATRICULA") {
        return "-";
    }
    const dueDateStr = new Date(dueDate);
    return `${dueDateStr.getDate()}/${dueDateStr.getMonth() + 1}/${dueDateStr.getFullYear()}`
}

function calculateTotalToPay(
    valorPago,
    recargo,
    descuentoBeca,
    descuentoHijos,
    descuentoOtros
) {
    const totalAPagarConMora =
        normalizeFloat(valorPago) + normalizeFloat(recargo);
    const totalDescuentos =
        normalizeFloat(descuentoBeca) +
        normalizeFloat(descuentoHijos) +
        normalizeFloat(descuentoOtros);
    const totalAPagarConDescuentos =
        normalizeFloat(totalAPagarConMora) -
        normalizeFloat(totalDescuentos);
    return totalAPagarConDescuentos;
}

function toUSDFormat(str) {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formatter.format(str);
}

function comparePaymentsConcept(a, b) {
    const ejercicioA = parseInt(a.ejercicio);
    const ejercicioB = parseInt(b.ejercicio);
    const conceptoA = a.concepto.toUpperCase();
    const conceptoB = b.concepto.toUpperCase();
    const mesA = parseFloat(a.mes);
    const mesB = parseFloat(b.mes);

    if (ejercicioA < ejercicioB) {
        return -1;
    } else if (ejercicioA > ejercicioB) {
        return 1;
    } else {
        const indiceA = conceptosOrdenados.indexOf(conceptoA);
        const indiceB = conceptosOrdenados.indexOf(conceptoB);

        if (indiceA < indiceB) {
            return -1;
        } else if (indiceA > indiceB) {
            return 1;
        } else {
            // Si las categorías son iguales, ordenar por mes
            if (mesA < mesB) {
                return -1;
            } else if (mesA > mesB) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}