<template>
  <v-dialog
    v-model="dialog"
    width="450px"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="elevation-0 rounded-xl">
      <v-card-title>
        <v-row>
          <v-col class="d-flex justify-center mb-4 mb-md-8 mt-3">
            <v-avatar size="110" color="indigo lighten-4">
              <v-icon color="indigo" x-large>mdi-account-badge-outline</v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-card
          class="grey lighten-4 elevation-0 pa-5 mb-2 mb-md-6 mx-md-5 rounded-lg"
        >
          <p class="text-h5 font-weight-bold text-center">
            Términos y condiciones
          </p>
          <v-row>
            <v-col cols="12">
              <p class="text-justify text-body-1">
                Yo,
                <span class="font-weight-bold"
                  >{{ studentEconomicManager.first_name }}
                  {{ studentEconomicManager.last_name }}</span
                >
                con Documento de Identidad número
                <span class="font-weight-bold">{{
                  studentEconomicManager.dui
                }}</span>
                declaro bajo juramento que la información proporcionada en la
                calidad en que actúo en el presente Formulario es veráz y
                demostrable a efecto de cumplir lo dispuesto en la Ley
                tributaria. Me comprometo a informar a la Asociación Institución
                Salesiana - Instituto Técnico Ricaldone, de cualquier cambio
                relativo a la misma en caso de que se presente o sea necesario
                adicionar información. Eximo de responsabilidad a los miembros
                de Junta Directiva, Asociados, directores, gerentes, jefes,
                supervisores y otro personal administrativo de servicios
                operativos con la Asociación Institución Salesiana - Instituto
                Técnico Ricaldone de toda responsabilidad, inclusive a terceros,
                si esta declaración fuese falsa o errónea.
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>

      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <v-card-actions v-if="showCardActions">
          <v-row class="mb-3 mb-md-5 px-2">
            <v-col cols="12" class="d-flex justify-center ">
              <v-btn
                @click="denied()"
                depressed
                large
                text
                color="red lighten-4 rounded-xl"
                :disabled="savingEconomicManagerState"
                :loading="savingEconomicManagerState"
              >
                <v-icon color="red" left>mdi-chevron-left</v-icon>
                <p class="mb-0 red--text">Regresar</p>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="accept()"
                depressed
                large
                color="success rounded-xl"
                :disabled="savingEconomicManagerState"
                :loading="savingEconomicManagerState"
              >
                Aceptar
                <v-icon right>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-scroll-y-reverse-transition>

      <v-scroll-y-reverse-transition
        :hide-on-leave="true"
        :leave-absolute="true"
      >
        <v-card-actions v-if="!showCardActions">
          <v-row class="mb-3 mb-md-5 px-5">
            <v-progress-linear
              class="my-7"
              indeterminate
              color="green"
            ></v-progress-linear>
          </v-row>
        </v-card-actions>
      </v-scroll-y-reverse-transition>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "registerStudentEconomicManagerTermsAndConditionsModal",

  data() {
    return {
      dialog: false,
      showCardActions: false,
    };
  },

  props: {
    studentEconomicManager: {
      type: Object,
    },

    savingEconomicManagerState: {
      type: Boolean,
    },
  },

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;

      this.setCounterForActionsApearance();
    },

    accept() {
      this.$emit("accept");
    },

    denied() {
      this.$emit("denied");
    },

    setCounterForActionsApearance() {
      if (this.dialog) {
        setTimeout(() => {
          this.showCardActions = true;
        }, 5000);
        return;
      }

      this.showCardActions = false;
    },
  },
};
</script>
