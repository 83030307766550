import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "@/router";

require("dotenv").config();

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        let url;
        if (process.env.APP_ENV === "production") {
            url = process.env.API_URL;
        } else if (process.env.APP_ENV === "testing") {
            url = process.env.DEV_API_URL;
        } else {
            url = process.env.LOCAL_API_URL;
        }
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = url;
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch((error) => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        let path;
        if (slug == "") {
            path = `${resource}`;
        } else if (Array.isArray(slug)) {
            const plainSlug = slug.join("/");
            path = `${resource}/${plainSlug}`;
        } else {
            path = `${resource}/${slug}`;
        }
        return Vue.axios.get(path).catch((error) => {
            if ("response" in error) {
                if ("status" in error.response && error.response.status === 401) {
                    window.location = process.env.BASE_URL + "#/login";
                }
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    },
};

export default ApiService;