<template>
  <div id="custom-turnstile" ref="turnstile"></div>
</template>

<script>
export default {
  name: "Turnstile",
  data() {
    return {
      siteToken: process.env.RECAPTCHA_TOKEN,
    }
  },
  mounted() {
    this.loadTurnstileScript();
  },
  methods: {
    loadTurnstileScript() {
      if (window.turnstile) {
        this.initTurnstile();
      } else {
        const script = document.createElement("script");
        script.setAttribute(
          "src",
          "https://challenges.cloudflare.com/turnstile/v0/api.js"
        );
        script.setAttribute("async", true);
        script.setAttribute("defer", true);

        script.onload = this.initTurnstile;

        document.head.appendChild(script);
      }
    },
    initTurnstile() {
      if (window.turnstile) {
        window.turnstile.render( `#${this.$refs.turnstile.id}`, {
          sitekey:this.siteToken,
          callback: (token) => {
            this.$emit('verificationFunction');
          }
        });
      }
    },
    reload() {
      if (this.turnstileInstance && window.turnstile) {
        window.turnstile.reset(this.turnstileInstance); // Resets the Turnstile widget
        this.initTurnstile(); // Re-initialize the Turnstile widget
      } else {
        this.loadTurnstileScript(); // If the script isn't loaded, load it again
      }
    },
  }
}
</script>